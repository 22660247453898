body {
  font-family: -apple-system, BlinkMacSystemFont,"Helvetica Neue", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Arial", sans-serif;
  // background-image: linear-gradient(to bottom, #fff 90%, #08f 90%);
  // background-size: 100% $scale;
  @extend .text-body;
}
p { @extend .text-p; }
h4 { @extend .text-h3; }
h3 { @extend .text-h3; }
h2 { @extend .text-h2; }
h1 { @extend .text-h1; }
code {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}

html {
  font-size: 12px; // 1rem
}

.text {
  $scale: 1rem;

  &-body {
    font-weight: 400;
    font-size: $scale * 1.25;
    line-height: $scale * 2;
  }

  &-p {
    @extend .text-body;
    margin-top: $scale * 1;
  }

  &-h3 {
    @extend .text-body;
    font-weight: 700;
    margin-top: $scale * 2;
  }

  &-h2 {
    font-weight: 700;
    font-weight: 900;
    font-size: $scale * 2.4;
    line-height: $scale * 3;
    margin-top: $scale * 3;
  }

  &-h1 {
    font-weight: 700;
    font-weight: 900;
    font-size: $scale * 4;
    line-height: $scale * 5;
    margin-top: $scale * 2;
  }
}
