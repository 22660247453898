@import "src/styles/Const.scss";
@import "src/styles/Theme.scss";

.PSelect {
  div:not([class*=PSelect]) {
    padding: 0 !important;
    margin: 0 !important;
    min-height: unset !important;
  }
  &-Item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__control {
    border: none !important;
    min-height: unset !important;
    @extend .input;
  }
  &__value-container {
    padding: 0 !important;
  }
  &__placeholder {
    color: $color-dim !important;
  }
  &__indicator {
    padding: 0 !important;
    color: $color-dim !important;
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    background-color: $color-window !important;
    color: $color-text !important;
    box-shadow: $shadow-m $color-shadow !important;
    margin-top: 1px !important;
  }
  &__option--is-focused {
    background-color: $color-link !important;
    color: $color-window !important;
  }
}
