@import "src/styles/Theme.scss";

.Modal {
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $color-shadow;
  &-Frame {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
  }
  &-Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  &-Window {
    margin: auto;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @extend .window;
  }
  &-Close {
    @extend .close-button;
  }
  &-Header {
    width: 100%;
    @extend .text-h2;
    padding: 0 2rem;
  }
  &-Footer {
    width: 100%;
    border-top: solid 1px $color-shadow;
    padding: 1rem 2rem;
  }
  &-Content {
    width: 100%;
    padding: 2rem;
  }
}
