@import "src/styles/Theme.scss";

.Table {
  width: 100%;
  height: 100%;

  &-Content {
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    z-index: 0;
    text-align: left;
    border-collapse: collapse;
    border: solid 1px $color-highlight;
  }

  &-Label {
    // background: red;
  }

  &-Cell {
    padding: .25rem .5rem;
    border-left: solid 1px $color-dim;
    border-right: solid 1px $color-dim;
    vertical-align: top;
    @extend .text-body;
    &_header {
      border-bottom: solid 1px $color-dim;
      font-weight: 600;
      user-select: none;
      position: sticky;
      top: 0;
      background-color: $color-window;
    }
    &_checkbox {
      width: 2rem;
    }
    &:first-child {
      border-left-color: $color-highlight;
    }
    &:last-child {
      border-right-color: $color-highlight;
    }
    &_sort &__cell { cursor: pointer; }
    &_sort &__name { text-decoration: underline; }
  }

  &-Row {
    transition-property: opacity, border-top-width, border-bottom-width;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    outline-offset: -1px;
    border: solid 1px transparent;
    &_on-drag {
      opacity: .5;
      outline: solid 1px $color-dim;
    }
    &_drop-zone {
      outline: solid 1px $color-hover;
    }
    &:nth-child(2n+1) {
      background: $color-highlight;
    }
  }

  &-Draggable {
    display: flex;
  }

  &-DragControl {
    margin-left: auto;
    cursor: pointer;
    color: $color-dim;
  }

  &-SortLabel {
    @extend .link;
    display: flex;
    align-items: center;
  }

  &-Sort {
    color: transparent;
    font-size: .9em;
    &_unsorted {
      color: $color-shadow;
    }
    &_sorted {
      color: inherit;
    }
  }

  &__group {
    // Unused?
    vertical-align: center;
    color: var(--color-button-bg);
    user-select: none;
    &_collapsed {
      color: var(--color-text);
      background-color: var(--color-link-shade);
    }
  }

  &-NotFound {
    display: flex;
    padding: 1rem;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    color: $color-dim;
  }
}
