* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  &:active{
    -webkit-tap-highlight-color: transparent;
  }
}
button:not([class]) {
  font-size: inherit;
}

hr {
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100vw;
}

input,
//select,
textarea,
fieldset,
button {
  padding: 0;
  margin: 0;
  border: none;
  border-width: 0;
  outline: none;
  border-radius: 0;
  appearance: none;
  background: none;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;
  }
}

input[type~=number] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
