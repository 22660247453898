// Sizes
$max-width: 120rem;

// Colors
$color-text: #22222c;
$color-window: #fff;
$color-button-bg: #f1f0f2;
$color-button-bg: #e9e8ea;
$color-button-text: $color-text;
$color-link: #6c5dd5;
$color-hover: #816fff;
$color-dim: #a4a2a0;
$color-highlight: #f4f2f2;
$color-accent: #ff0056;
$color-done: #35a900;
$color-warning: #a90035;
$color-shadow: rgba(0, 0, 32, .2);

.color- {
  &text {color: $color-text}
  &window {color: $color-window}
  &button-text {color: $color-button-text}
  &button-bg {color: $color-button-bg}
  &link {color: $color-link}
  &hover {color: $color-hover}
  &dim {color: $color-dim}
  &highlight {color: $color-highlight}
  &accent {color: $color-accent}
  &done {color: $color-done}
  &warning {color: $color-warning}
  &shadow {color: $color-shadow}
  &unset {color: unset}
  &inherit {color: inherit}
}

.bg- {
  &text {background-color: $color-text}
  &window {background-color: $color-window}
  &button-text {background-color: $color-button-text}
  &button-bg {background-color: $color-button-bg}
  &link {background-color: $color-link}
  &hover {background-color: $color-hover}
  &dim {background-color: $color-dim}
  &highlight {background-color: $color-highlight}
  &accent {background-color: $color-accent}
  &done {background-color: $color-done}
  &warning {background-color: $color-warning}
  &shadow {background-color: $color-shadow}
  &unset {background-color: unset}
}


// Shadows
$shadow-s: 0 1px 3px -1px;
$shadow-m: 0 .5rem 1rem;
$shadow-l: 0 1rem 3rem -.5rem;
$shadow-border: 0 0 0 1px;

.shadow- {
  &s {box-shadow: $shadow-s $color-shadow}
  &m {box-shadow: $shadow-m $color-shadow}
  &l {box-shadow: $shadow-l $color-shadow}
  &border {box-shadow: $shadow-border $color-shadow}
}


// Radius
$radius-s: 4px;
$radius-m: 8px;
$radius-l: 16px;
$radius-100: 100%;

.radius- {
  &s {border-radius: $radius-s}
  &m {border-radius: $radius-m}
  &l {border-radius: $radius-l}
  &100 {border-radius: $radius-100}
}

// Transition speed
$transition-s: 100ms;
$transition-m: 300ms;
$transition-l: 900ms;
