@import "src/styles/Const.scss";

.Layout {
	&-Row {
		max-width: $max-width;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
		flex-wrap: wrap;
		& > * {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	&-Col {
		@extend .Layout-Row;
		// margin: 0;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: flex-start;
	}
	&-Col &-Row,
	&-Col &-Col,
	&-Cow &-Row,
	&-Row &-Col {
		margin-left: 0;
		margin-right: 0;
		& > * {
			padding-left: unset;
			padding-right: unset;
		}
	}
	&-Toolbar {
		// margin-left: -1rem;
		// margin-right: -1rem;
		overflow: hidden;
		display: flex;
		// flex: auto;
		width: 100%;
		align-items: center;
		& > * {
			margin-left: .25rem;
			margin-right: .25rem;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&-Space {
		flex: auto;
	}
	&-Gap {
		width: 100%;
	}
	&-Separator {
		background: currentColor;
		flex: 0 0 1px;
		padding: 0;
	}
}
