// Utilities
.none {display: none;}
.hidden {visibility: hidden;}
.no-click {pointer-events: none;}
.no-select {user-select: none;}
.underline {text-decoration: solid};
.underline-none {text-decoration: none};


.overflow- {
	&hidden {overflow: hidden}
	&x-hidden {overflow-x: hidden}
	&y-hidden {overflow-y: hidden}
	&auto {overflow: auto}
	&x-auto {overflow-x: auto}
	&y-auto {overflow-y: auto}
	&overlay {overflow: overlay}
	&x-overlay {overflow-x: overlay}
	&y-overlay {overflow-y: overlay}
}

.pos- {
	&fixed {position: fixed;}
	&sticky {position: sticky;}
	&relative {position: relative;}
	&absolute {position: absolute;}
}

.d- {
	&inline {display: inline}
	&block {display: block}
	&inline-block {display: inline-block}
	&flex {display: flex}
	&inline-flex {display: inline-flex}
}

.flex- {
	&row {flex-direction: row}
	&row-reverse {flex-direction: row-reverse}
	&column {flex-direction: column}
	&column-reverse {flex-direction: column-reverse}
	&auto {flex: auto}
	&nowrap {flex-wrap: nowrap}
	&grow-1 {flex-grow: 1}
}
.align-items- {
	&start {align-items: flex-start;}
	&end {align-items: flex-end;}
	&center {align-items: center;}
}
.justify-content- {
	&start {align-items: flex-start;}
	&end {align-items: flex-end;}
	&center {align-items: center;}
}

.text- {
	&left {text-align: left;}
	&center {text-align: center;}
	&right {text-align: right;}
	&nowrap {white-space: nowrap;}
}

// Columns
@each $size in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 {
	.col-#{$size} {flex: 0 0 100%/12 * $size}
}

// Width
@each $size in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 60 {
	.w-#{$size}r {
		width: $size * 1rem;
	}
	.h-#{$size}r {
		height: $size * 1rem;
	}
	.wmin-#{$size}r {
		min-width: $size * 1rem;
	}
	.wmax-#{$size}r {
		max-width: $size * 1rem;
	}
}
@each $size in 25, 33, 50, 67, 75, 100 {
	.w-#{$size}p { width: $size * 1%; }
	.w-#{$size}p { width: $size * 1%; }
	.h-#{$size}p { height: $size * 1%; }
	.w-#{$size}vw { width: $size * 1vw; }

	.hmin-#{$size}vh { min-height: $size * 1vh; }
	.hmin-#{$size}p { min-height: $size * 1%; }
	.wmin-#{$size}vw { min-width: $size * 1vw; }
	.hmin-#{$size}vh { min-height: $size * 1vh; }

	.hmax-#{$size}vh { max-height: $size * 1vh; }
	.hmax-#{$size}p { max-height: $size * 1%; }
	.wmax-#{$size}vw { max-width: $size * 1vw; }
	.hmax-#{$size}vh { max-height: $size * 1vh; }
}
.w-auto {width: auto}
@each $size in 4, 8, 16, 24, 32, 64, 128, 176, 256, 384, 512, 640, 768 {
	.w-#{$size} {
		width: #{$size}px;
	}
	.wmin-#{$size} {
		min-width: #{$size}px;
	}
	.wmax-#{$size} {
		max-width: #{$size}px;
	}
	.h-#{$size} {
		height: #{$size}px;
	}
}

// Margin, padding
@each $size in 2, 4, 8, 16, 24, 32, 64, 5, 10, 15, 20, 25, 30, 50, 100 {
	.p-#{$size}  {padding: #{$size}px;}
	.pt-#{$size} {padding-top: #{$size}px;}
	.pl-#{$size} {padding-left: #{$size}px;}
	.pr-#{$size} {padding-right: #{$size}px;}
	.pb-#{$size} {padding-bottom: #{$size}px;}
	.pv-#{$size} {padding-top: #{$size}px; padding-bottom: #{$size}px;}
	.ph-#{$size} {padding-left: #{$size}px; padding-right: #{$size}px;}

	.m-#{$size}  {margin: #{$size}px;}
	.mt-#{$size} {margin-top: #{$size}px;}
	.ml-#{$size} {margin-left: #{$size}px;}
	.mr-#{$size} {margin-right: #{$size}px;}
	.mb-#{$size} {margin-bottom: #{$size}px;}
	.mv-#{$size} {margin-top: #{$size}px; margin-bottom: #{$size}px;}
	.mh-#{$size} {margin-left: #{$size}px; margin-right: #{$size}px;}
}
@each $size in 1, 2, 3, 4, 5, 6 {
	.p-#{$size}r  {padding: #{$size}rem;}
	.pt-#{$size}r {padding-top: #{$size}rem;}
	.pl-#{$size}r {padding-left: #{$size}rem;}
	.pr-#{$size}r {padding-right: #{$size}rem;}
	.pb-#{$size}r {padding-bottom: #{$size}rem;}
	.pv-#{$size}r {padding-top: #{$size}rem; padding-bottom: #{$size}rem;}
	.ph-#{$size}r {padding-left: #{$size}rem; padding-right: #{$size}rem;}

	.m-#{$size}r  {margin: #{$size}rem;}
	.mt-#{$size}r {margin-top: #{$size}rem;}
	.ml-#{$size}r {margin-left: #{$size}rem;}
	.mr-#{$size}r {margin-right: #{$size}rem;}
	.mb-#{$size}r {margin-bottom: #{$size}rem;}
	.mv-#{$size}r {margin-top: #{$size}rem; margin-bottom: #{$size}rem;}
	.mh-#{$size}r {margin-left: #{$size}rem; margin-right: #{$size}rem;}
}
.m-a {margin: auto}
.mt-a {margin-top: auto}
.ml-a {margin-left: auto}
.mr-a {margin-right: auto}
.mv-a {margin-top: auto; margin-bottom: auto}
.mh-a {margin-left: auto; margin-right: auto}

.p-05r  {padding: 0.5rem;}
