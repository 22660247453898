@import "src/styles/Theme.scss";

.Pager {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  // margin-bottom: 20px;

  &-Separator {
    color: $color-dim;
  }
  &-Items {
    // padding-right: 9px;
  }
  &-Control {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 1rem;
  }
  &-Button {
    @extend .text-body;
    @extend .link;
    &_left {
      padding-right: .5rem;
    }
    &_right {
      padding-left: .5rem;
    }
  }
}
