@import "src/styles/Text.scss";
@import "src/styles/Const.scss";

// Animations
@keyframes opacity {
	0% {opacity: 0}
	1000% {opacity: 1}
}


// Links
a {
	@extend .link;
}
.link {
	color: $color-link;
	transition: color $transition-m ease;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
		// color: $color-hover;
	}
}

// Buttons
.button {
	@extend .text-p;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-top: 0;
	padding: .5rem;
	color: $color-button-text;
	border-radius: $radius-s;
	cursor: pointer;
	transition-property: box-shadow, color, transform;
	transition-duration: $transition-s;
	transition-timing-function: ease;
	background-color: $color-button-bg;
	text-align: center;
	text-decoration: none;
	// decorative
	$glare: inset 0 2px 0 0;
	$shadow: inset 0 0 0 1px;
	box-shadow: 0 0 0 1px rgba($color-hover, 0), $shadow $color-shadow, $glare $color-window;
	&:focus-within,
	&:focus {
		box-shadow: 0 0 0 1px $color-hover, inset 0 0 0 1px $color-link, inset 0 -2px 0 -1px rgba($color-shadow, 0);
		color: $color-hover;
	}
	&:hover {
		box-shadow: $shadow $color-hover, $glare $color-window;
		color: $color-hover;
		text-decoration: none;
	}
	&:active {
		box-shadow: $shadow $color-shadow, $glare $color-shadow;
		color: $color-text;
	}
	&:disabled {
		color: $color-dim;
		box-shadow: unset;
		pointer-events: none;
	}
}

// Label
.label {
	@extend .text-body;
	display: block;
}

// Input
.input {
	@extend .text-body;
	display: flex;
	flex: auto;
	width: 100%;
	align-items: center;
	padding: .5rem;
	//width: 100%;
	background-color: $color-window;
	color: $color-button-text;
	border-radius: $radius-s;
	resize: none;
	transition: box-shadow $transition-s ease;
	box-shadow: 0 0 0 1px rgba($color-hover, 0), inset 0 0 0 1px $color-shadow, inset 0 -2px 0 -1px $color-shadow;
	&:focus-within,
	&:focus {
		box-shadow: 0 0 0 1px $color-hover, inset 0 0 0 1px $color-link, inset 0 -2px 0 -1px rgba($color-shadow, 0);
	}
	&::placeholder,
	input, textarea {
		@extend .text-body;
	}
	textarea::placeholder,
	input::placeholder {
		color: $color-dim;
	}
}

.checkbox {
	width: 1.2rem;
	height: 2rem;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
	&::before {
		content: "";
		width: inherit;
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		background-color: $color-window;
		color: $color-button-text;
		border-radius: $radius-s;
		transition-property: box-shadow $transition-s ease;
		box-shadow: 0 0 0 1px rgba($color-hover, 0), inset 0 0 0 1px $color-shadow, inset 0 -2px 0 -1px $color-shadow;
	}
	&:checked::after {
		transform: rotate(-45deg) scale(1);
	}
	// &:checked::after {
	// 	transform: rotate(-45deg) scale(1);
	// }
	&::after {
		content: "";
		width: inherit;
		display: block;
		position: absolute;
		width: 67%;
		height: 0;
		padding-bottom: 25%;
		left: .15rem;
		bottom: .9rem;
		border-left: solid 2px $color-hover;
		border-bottom: solid 2px $color-hover;
		transition: transform $transition-s ease;
		transform: rotate(-45deg) scale(0);
	}
}

.window {
	background-color: $color-window;
	box-shadow: $shadow-l $color-shadow;
	border-radius: $radius-m;
	display: flex;
	flex-direction: column;
}

.close-button {
	position: absolute;
	right: -1rem;
	top: -1rem;
	width: 3rem;
	min-width: 3rem;
	max-width: 3rem;
	height: 3rem;
	min-height: 3rem;
	max-height: 3rem;
	padding: 0;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $color-window;
	color: $color-text;
	box-shadow: $shadow-m $color-shadow;
	transition: color $transition-m ease;
	cursor: pointer;
	&:hover {
		color: $color-hover;
	}
}

.card {
	background-color: $color-window;
	box-shadow: $shadow-l $color-shadow;
	border-radius: $radius-m;
	// overflow: hidden;
}

